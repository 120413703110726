import React,{useState,useEffect,useRef} from "react"

import {v4 as uuidv4} from "uuid"
import {API_NOTIFICATIONS} from "src/constants/constants"

import {useOnClickOutside} from "src/hooks/hooks"

const Notifications = () => {
    // const [isTips,setIsTips] = useState(false)
    // const [isNews,setIsNews] = useState(false)
    const [activeMenu,setActiveMenu] = useState({
        "tips":false,
        "news":false
    })

    const [tipCardData,setTipCardData] = useState([])
    const [newsCardData,setNewsCardData] = useState([])

    const toggleTipsMenu = () => {
        // setIsTips(!isTips)
        setActiveMenu({
            "news":false,
            "tips":!activeMenu.tips
        })
    }

    const toggleNewsMenu = () => {
        // setIsNews(false)
        // setIsNews(!isNews)
        setActiveMenu({
            "news":!activeMenu.news,
            "tips":false
        }) 
    }

    const setTipsMenuData = (xmlDoc) => {
        let articles = []
        let card_data = Array.from(xmlDoc.getElementsByTagName("tip"))
        card_data.forEach(card => {
            let card_desc = card.querySelector("description");
            let desc = ''
            let img = ''
            let link = ''
            for (let i = 0; i < card_desc.childNodes.length -1; i++) {
                let data = card_desc.childNodes[i]
                if(data.tagName === "link"){
                    link = data.querySelector("page_url").textContent
                    img = data.querySelector("image").textContent
                }
                else if(data.tagName === "text"){
                    desc += `<span>${data.textContent}</span>`
                }  
                else if(data.tagName === "url"){
                    desc += `<a class="text-blue" href="${data.textContent}">${data.textContent}</a>`
                }    
            }
            const template = (
                <article className="notification-card" key={uuidv4()}>
                    <a href={link} target="_blank">
                        {
                            img !== ''
                            &&
                            <img width="768" height="783" src={img} />
                        }     
                        <div className="announcement-text-container px-20">
                            <h4>{card.querySelector("title").textContent}</h4>
                            <p dangerouslySetInnerHTML={{__html:(desc.length > 170 && link !== '') ? desc.substr(0,170)+"...":desc}} ></p>
                        </div>
                        {
                            link !== '' &&
                            <span className="text-red my-2 inline-block" href={link} >READ MORE...</span>  
                        }    
                    </a>
                </article>
            );
            
            articles.push(template)
        })
        setTipCardData(articles)
        // localStorage.setItem("tip_updates_data",articles)
    }

    const setNewsMenuData = (data) => {
        let articles = []
        let div = document.createElement("div")
        div.innerHTML = data

        div.querySelectorAll(".elementor-post__card").forEach(article=>{
            let a = article.querySelector("a").getAttribute("href")
            let img = article.querySelector("img") !== null ? article.querySelector("img").getAttribute("src"):""
            let title = article.querySelector("h2 a").textContent
            let desc = article.querySelector(".elementor-post__excerpt p").textContent
            let date = article.querySelector(".elementor-post-date").textContent
            // art.push([a,img,title,desc,date])

            const template = (
                <article className="notification-card" key={uuidv4()}>
                    <a href={a} target="_blank">                    
                        <img width="768" height="783" src={img}/>                        
                        <div className="announcement-text-container text-center px-20">
                            <h4>{title}</h4>
                            <p>{desc}</p>    
                        </div>    
                        <span className="text-red my-2 inline-block" href={a} >READ MORE...</span>
                        <div className="date">
                            <span>{date}</span>
                        </div>
                    </a>
                </article>
            )
            articles.push(template)

        })
        setNewsCardData(articles)
        // localStorage.setItem("announcement_updates_data",articles)
    }

    const ref_modal = useRef();
    const ref_tips = useRef();
    const ref_news = useRef();

    useEffect(() => {
        fetch(`${API_NOTIFICATIONS}/tips.php`)
        .then(res => res.text())
        .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
        .then(response => setTipsMenuData(response))
        .catch(err => console.log(err))


        fetch(`${API_NOTIFICATIONS}/updates.php`)
        .then(res => res.text())
        .then(response => setNewsMenuData(response))
        .catch(err => console.log(err)) 

    },[])

    useOnClickOutside(ref_modal,ref_tips,ref_news, () => {
        setActiveMenu({
            "news":false,
            "tips":false
        })
    });

    return(
        <React.Fragment>
            <i className={"notification-icon icon feather icon-info "+(activeMenu.tips ? "active":"")} onClick={toggleTipsMenu} title="See Tips List" ref={ref_tips} />
            <i className={"notification-icon icon feather icon-bell ml-10 "+(activeMenu.news ? "active":"")} onClick={toggleNewsMenu} title="See New Features List" ref={ref_news} />

            <div className={"articles-container "+(activeMenu.tips || activeMenu.news ? "open":"")} ref={ref_modal}>
                {
                    activeMenu.tips &&
                        <React.Fragment>
                            {
                                tipCardData.map(card => card)
                            }
                        </React.Fragment>
                }

                {
                    activeMenu.news &&
                        <React.Fragment>
                            {
                                newsCardData.map(card => card)
                            }
                        </React.Fragment>
                }
            </div>

        </React.Fragment>
    )
}

export default Notifications