import React,{useContext,useState} from "react"
import { useMediaQuery } from 'react-responsive'

import {store} from "src/context/store"
import {Link} from "gatsby"

import Logo from "images/zon-favicon.png"

import Avatar from "images/img_avatar1.png"

import {
    ActiveMarket
} from "src/constants/constants"

import ArrowDropDown_Icon from "icons/custom-select-arrow.svg"
import {Button} from "components/Button/Button"

const Menu = ({showActions,showBulk}) => {
    const [isOpen,setIsOpen] = useState({
        "search-terms":false,
        "negatives":false,
        "history":false,
    })

    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })

    const globalState = useContext(store)
    const {dispatch} = globalState
 
    const toggleSideMenu = () => {
        dispatch({type:'TOGGLE_MENU'})
        setIsOpen({
            "search-terms":false,
            "negatives":false,
            "history":false,
        })
    }

    const showAddNewModal = () => {
        dispatch({type:'TOGGLE_ADD_NEW_MODAL',payload:true})
    }
    const showBulkActionModal = () => {
        dispatch({type:'TOGGLE_BULK_ACTION_MODAL',payload:true})
    };

    const toggleSubMenu = (menu) => {
        // if(!isMobile && globalState?.state?.isMenuOpen){
            if(globalState?.state?.isMenuOpen && !isMobile){
                toggleSideMenu()
            }
            setIsOpen(prev =>({
                ...prev,
                [menu]:!isOpen[menu]
            }))
        // }
    }

    // window.location.pathname

    const storefront = globalState?.state?.userConfig?.[ActiveMarket]?.["storefront"]
    
    const accountType = globalState?.state?.accountConfig?.["tier"]

    const navContent = (
        <div className="navbar-content">
            <ul className="nav inner-navbar">
                <li className="nav-item menu-caption user-info">
                    <p>Welcome, Muhammad</p>
                    <img src={Avatar} alt="Avatar" width={45} height={45}/>
                </li>
                {
                    showActions &&
                    <>
                        <li className="nav-item menu-caption">
                            <span className="label">Actions</span>
                        </li>
                        <div className={"mbtn-container "+( (globalState?.state?.isMenuOpen && !isMobile) ? "small":"")}>
                            {/* <button className="btn bg-green" onClick={showAddNewModal}>
                                <i className="fa fa-plus-circle"></i> <strong className="mtext">Add New</strong>
                            </button> */}
                            {
                                accountType !== "Analyzer"
                                &&
                                <Button className={"btn bg-green"} onClick={showAddNewModal}>
                                    <i className="fa fa-plus-circle"></i> <strong className="mtext">Add New</strong>
                                </Button>
                            }
                            {
                                showBulk === undefined
                                &&
                                <Button className="btn bg-purple" onClick={showBulkActionModal}>
                                    <i className="fa fa-bolt"></i> <strong className="mtext">Bulk Action</strong>
                                </Button>
                            }
                        </div>
                    </>
                }
                <li className="nav-item menu-caption">
                    <span className="label">Overview</span>
                </li>
                <li data-tooltip="Dashboard">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/">
                        <span className="micon"><i className="feather icon-home"></i></span>
                        <span className="mtext">Dashboard</span>
                    </Link>
                </li>
                <li data-tooltip="Outlook of All Accounts">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/statistics">
                        <span className="micon"><i className="fa fa-eye fa-fw"></i></span>
                        <span className="mtext">Outlook of All Accounts</span>
                    </Link>
                </li>
                {/* <li>
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/">
                        <span className="micon"><i className="fa fa-eye fa-fw"></i></span>
                        <span className="mtext">Automations Overview</span>
                    </Link>
                </li> */}
                <li className="nav-item menu-caption">
                    <span className="label">PGN Product Groups</span>
                </li>
                
                {
                    accountType !== "Analyzer"
                    &&
                    <li data-tooltip="PGN">
                        <Link aria-current="page" activeClassName="active" className="nav-link" to="/productgroup">
                            <span className="micon">
                                <i className="fa fa-cubes fa-fw"></i>
                            </span>
                            <span className="mtext">PGN</span>
                        </Link>
                    </li>
                }
                <li data-tooltip="Campaigns">
                    <Link aria-current="page" activeClassName="active" className={"nav-link"} to="/campaign">
                        <span className="micon">
                            <i className="fa fa-bullhorn"></i>
                        </span>
                        <span className="mtext">Campaigns</span>
                    </Link>
                </li>
                <li data-tooltip="Ad Groups">
                    <Link aria-current="page" partiallyActive={true} activeClassName="active" className="nav-link" to="/adgroup">
                        <span className="micon">
                            <i className="fa fa-object-group"></i>
                        </span>
                        <span className="mtext">Ad Groups</span>
                    </Link>
                </li>
                <li data-tooltip="Keywords">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/keyword">
                        <span className="micon">
                            <i className="fa fa-key"></i>
                        </span>
                        <span className="mtext">Keywords</span>
                    </Link>
                </li>
                <li data-tooltip="Targets">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/target">
                        <span className="micon">
                            <i className="fa fa-dot-circle-o"></i>
                        </span>
                        <span className="mtext">Targets</span>
                    </Link>
                </li>
                <li data-tooltip="Products">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/productad">
                        <span className="micon">
                            <i className="fa fa-cube fa-fw"></i>
                        </span>
                        <span className="mtext">Products</span>
                    </Link>
                </li>
                <li data-tooltip="ASINs">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/asin">
                        <span className="micon">
                            <i className="fa fa-dot-circle-o"></i>
                        </span>
                        <span className="mtext">ASINs</span>
                    </Link>
                </li>
                <li data-tooltip="Campaign Placement Reports">
                    <Link aria-current="page" activeClassName="active" className="nav-link" to="/placeCm">
                        <span className="micon">
                            <i className="fa fa-newspaper-o fa-fw"></i>
                        </span>
                        <span className="mtext">Campaign Placement Reports</span>
                    </Link>
                </li>
                <li data-tooltip="Search Terms">
                    <p className={"nav-link multi "+(isOpen["search-term"] ? "active":"")} onClick={() => toggleSubMenu("search-term")}>
                        <span>
                            <span className="micon">
                                <i className="fa fa-sitemap fa-fw"></i>
                            </span>
                            <span className="mtext">Search Terms</span>
                        </span>
                        <ArrowDropDown_Icon className={isOpen["search-term"] ? 'open arrow_dropdown':'close arrow_dropdown'}/>
                    </p>
                    {
                        isOpen["search-term"] &&
                        <ul className="sub-links">
                            <li>
                                <p> <i className="fa fa-key fa-fw"></i> &emsp;Grouping Keyword Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-key fa-fw"></i> &emsp;Grouping Ad Group Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-key fa-fw"></i> &emsp;Grouping Campaign Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-key fa-fw"></i> &emsp;Grouping Account Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Grouping Target Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Grouping Ad Group Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Grouping Campaign Level</p>
                            </li>
                            <li>
                                <p> <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Grouping Account Level</p>
                            </li>
                        </ul>
                    }
                </li>
                <li data-tooltip="Negatives">
                    <p className={"nav-link multi "+(isOpen["negatives"] ? "active":"")} onClick={() => toggleSubMenu("negatives")}>
                        <span>
                            <span className="micon">
                                <i className="fa fa-ban fa-fw"></i>
                            </span>
                            <span className="mtext">Negatives</span>
                        </span>
                        <ArrowDropDown_Icon className={isOpen["negatives"] ? 'open arrow_dropdown':'close arrow_dropdown'}/>
                    </p>
                    {
                        isOpen["negatives"] &&
                        <ul className="sub-links">
                            <li>
                                <p><i className="fa fa-key fa-fw"></i> &emsp;Neg. Keywords [Campaign]</p>
                            </li>
                            <li>
                                <p><i className="fa fa-key fa-fw"></i> &emsp;Neg. Keywords [Ad Group]</p>
                            </li>
                            <li>
                                <p><i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Neg. Targets [Ad Group]</p>
                            </li>
                        </ul>
                    }
                </li>
                <li data-tooltip="History Logs">
                    <p className={"nav-link multi "+(isOpen["history"] ? "active":"")} onClick={() => toggleSubMenu("history")}>
                        <span>
                            <span className="micon">
                                <i className="fa fa-history fa-fw"></i>
                            </span>
                            <span className="mtext">History Logs</span>
                        </span>
                        <ArrowDropDown_Icon className={isOpen["history"] ? 'open arrow_dropdown':'close arrow_dropdown'}/>
                    </p>
                    {
                        isOpen["history"] &&
                        <ul className="sub-links">
                            <li>
                                <Link>
                                    <i className="fa fa-cubes fa-fw"></i> &emsp;Product Group Logs (PGN)
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-bullhorn fa-fw"></i> &emsp;Campaign Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-object-group fa-fw"></i> &emsp;Ad Group Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-cube fa-fw"></i> &emsp;Product Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-key fa-fw"></i> &emsp;Keyword Automation Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-key fa-fw"></i> &emsp;Keyword Miner Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-key fa-fw"></i> &emsp;Term-Inator Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-key fa-fw"></i> &emsp;Keyword All Change Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Target Automation Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Target Miner Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;ASIN-Ator Logs
                                </Link>
                            </li>
                            <li>
                                <Link>
                                    <i className="fa fa-dot-circle-o fa-fw"></i> &emsp;Target All Change Logs
                                </Link>
                            </li>
                                    {/* <a href="productgroup_log.php"></a>
                                    <a href="campaign_log.php"></a>
                                    <a href="adgroup_log.php"></a>
                                    <a href="productad_log.php"></a>
                                    <a href="keyword_automation_log.php"></a>
                                    <a href="keywordchange_log.php?searchField=keywordtype&searchText=positive"></a>
                                    <a href="keywordchange_log.php?searchField=keywordtype&searchText=negative"></a>
                                    <a href="keyword_log.php"></a>
                                    <a href="target_automation_log.php"></a>
                                    <a href="targetchange_log.php?searchField=targettype&searchText=positive"></a>
                                    <a href="targetchange_log.php?searchField=targettype&searchText=negative"></a>
                                    <a href="target_log.php"></a> */}
                        </ul>
                    }
                </li>
                <li className="nav-item menu-caption">
                    <span className="label">PAGES</span>
                </li>
                <li data-tooltip="Account Dashboard">
                    <a href="https://account.zon.tools" className="nav-link">
                        <span className="micon">
                            <i className="fa fa-dashboard fa-fw"></i>
                        </span>
                        <span className="mtext">Account Dashboard</span>
                    </a>
                </li>
                {
                    storefront !== undefined &&
                    <li data-tooltip="Amazon Storefront">
                        <a href={storefront} className="nav-link">
                            <span className="micon">
                                <i className="fa fa-fort-awesome fa-fw"></i>
                            </span>
                            <span className="mtext">Amazon Storefront</span>
                        </a>
                    </li>
                }
                <li data-tooltip="Tutorials">
                    <a href="https://www.zon.tools/tutorials/" className="nav-link">
                        <span className="micon">
                            <i className="fa fa-graduation-cap fa-fw"></i>
                        </span>
                        <span className="mtext">Tutorials</span>
                    </a>
                </li>
                <li data-tooltip="Support">
                    <a href="https://support.zon.tools" className="nav-link">
                        <span className="micon">
                            <i className="fa fa-question-circle fa-fw"></i>
                        </span>
                        <span className="mtext">Support</span>
                    </a>
                </li>
            </ul>
        {/* </div> */}
        </div>    
    )

    if(isMobile){
        return(
            <React.Fragment>
                <div className="navbar-brand header-logo mobile">
                    <span className="b-brand">
                        <img src={Logo} alt="Zon Tools Logo" width={35} height={35} />
                        <p className="b-title">Zon Tools</p>
                    </span>
                    <button className="mobile-menu" id="mobile-collapse" tabIndex="0" onClick={toggleSideMenu}>
                        <span></span>
                    </button>
                </div>
                
                {globalState?.state?.isMenuOpen && <div className="menu-overlay" onClick={toggleSideMenu}></div>}
                <div className={"sidemenu-mobile "+(globalState?.state?.isMenuOpen ? "open":"close")}>
                    {navContent}
                </div>
            </React.Fragment>
        )
    }

    return(
        <React.Fragment>
            <nav className={"navbar "+(globalState?.state?.isMenuOpen ? "mob":"")}>
                <div className="navbar-wrapper">
                    <div className="navbar-brand header-logo desktop">
                        <span className="b-brand">
                            <img src={Logo} alt="Zon Tools Logo" width={35} height={35} />
                            <span className="b-title">Zon Tools</span>
                        </span>
                        <button className="mobile-menu" id="mobile-collapse" tabIndex="0" onClick={toggleSideMenu}>
                            <i className={"fa "+(globalState?.state?.isMenuOpen?"fa-angle-double-right":"fa-angle-double-left")}></i>
                            <span></span>
                        </button>
                    </div>
                    {navContent}              
                </div>
            </nav>
        </React.Fragment>
    )
}

export default Menu