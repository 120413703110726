import React,{useState,useContext,useEffect} from "react"
import { useMediaQuery } from 'react-responsive'

import {store} from "src/context/store"
import {CustomSelect} from "components/CustomSelect/CustomSelect"

import SideMenu from "components/Layout/Navigation/SideMenu"
import Notifications from "components/Layout/Notifications/Notifications"
// import Logo from "images/zon-favicon.png"

import {
    ActiveMarketName,
    ActiveMarket
} from "src/constants/constants"

import Sandbox from "images/sandbox.png"
import DeFlag from "images/flags/de.png"
import JpFlag from "images/flags/jp.png"
import UkFlag from "images/flags/uk.png"
import UsFlag from "images/flags/us.png"
import CaFlag from "images/flags/ca.png"
import EsFlag from "images/flags/es.png"
import FrFlag from "images/flags/fr.png"
import NlFlag from "images/flags/nl.png"
import ItFlag from "images/flags/it.png"
import InFlag from "images/flags/in.png"

import {v4 as uuidv4} from "uuid"

const Layout = ({children,showActions,showBulk}) => {
    const [img,setImg] = useState()
    const [marketplace,setMarketplace] = useState()

    const isMobile = useMediaQuery({ query: '(max-width: 992px)' })
    
    const globalState = useContext(store)
    // const {dispatch} = globalState

    const MARKETS = {
        "marketplacesExt":"External",
        "marketplacesFe":"French",
        "marketplacesEu":"Europe",
        "marketplacesNa":"North America",        
    }    

    const FLAGS = {
        LOGO:Sandbox,
        DE:DeFlag,
        US:UsFlag,
        UK:UkFlag,
        JP:JpFlag,
        CA:CaFlag,
        ES:EsFlag,
        FR:FrFlag,
        NL:NlFlag,
        IT:ItFlag,
        IN:InFlag
    }

    useEffect(() => {
        if(globalState?.state?.accountConfig?.marketplace !== undefined){
            import(`images/flags/${globalState?.state?.accountConfig?.marketplace?.toLowerCase()}.png`).then(image => {
                setImg(image.default)
            });
        }
    },[globalState?.state?.accountConfig])

    const pageContent = (
        <div className="page-content">
            <div className="top-menu">
                <div className="flex">
                    <div className="left">
                        <img src={img} className="marketplace-logo" alt={`${globalState?.state?.accountConfig?.marketplace} Marketplace`} width={32} height={32}/>
                        <CustomSelect setValue={setMarketplace} defaultValue={ActiveMarketName} icon={true}>
                            {
                                globalState?.state?.userMarkets !== undefined
                                &&
                                Object.keys(globalState?.state?.userMarkets).map(market => {
                                    if(globalState?.state?.userMarkets[market].length !== 0){
                                        let head = <p className="group-heading">---{MARKETS[market]}---</p>
                                        let body = globalState?.state?.userMarkets[market].map(m => {
                                                let countryCode = globalState?.state?.userConfig[m]["countryCode"]
                                                return(
                                                    <p key={uuidv4()} value={m}>
                                                        <img src={FLAGS[countryCode]} alt={`${countryCode} Marketplace`}  width={25} height={25}/>
                                                        {countryCode}-{globalState?.state?.userConfig[m]["company"]}
                                                    </p>
                                                )
                                            }
                                        )
                                        return (
                                            <React.Fragment key={uuidv4()}>
                                                {head}
                                                {body.map(b => b)}
                                            </React.Fragment>
                                        )
                                    }
                                })
                            }
                        </CustomSelect>
                    </div>
                    
                    <div className="right">
                        <Notifications/>
                        <i className="icon feather icon-log-out mx-10"></i>
                    </div>
                </div>
            </div>
            {children}
        </div>
    )

    return(
        <>
            {
                isMobile ?
                    <div className={"page-wrapper mobile"}>
                        <SideMenu showActions={showActions} showBulk={showBulk}/>
                        {pageContent}
                    </div>
                :
                    <div className={"page-wrapper "+(globalState?.state?.isMenuOpen ? "mob":"")}>
                        <div className="side-menu">
                            <SideMenu showActions={showActions} showBulk={showBulk}/>
                        </div>
                        {pageContent}
                    </div>
            }
        </>
    )
}

export default Layout