import React, { 
    useEffect, 
    useState, 
    useRef 
} from "react"

import ArrowDropDown_Icon from "icons/custom-select-arrow.svg"

import {useOnClickOutside} from "src/hooks/hooks"
// import { v4 as uuidv4 } from 'uuid';

export const CustomSelect = (props) => {
    const [isOpen,setOpen] = useState(false)
    const [placeholder,setPlaceholder] = useState(props.defaultValue)

    const handleDropDown = () => {
        setOpen(!isOpen)
    }

    const selectValue = (e) => {
        let value = e.target.getAttribute("value")
        setPlaceholder(e.target.innerText)
        props?.setPlaceholder && props.setPlaceholder(e.target.innerText)
        props?.setValue && props.setValue(value)

        props?.setObj && props.setObj({
            [props.objName]:value
        })

        handleDropDown()
    }

    const ref_inner = useRef();
    const ref_outer = useRef();

    useEffect(() => {
        setPlaceholder(props.defaultValue)
    },[props.defaultValue])

    useOnClickOutside(ref_inner,ref_outer, () => setOpen(false));

    const maxLength = props.max === undefined ? 18:props.max
    return(
        <React.Fragment>
            <div className="custom-select">
                <p ref={ref_outer} onClick={handleDropDown} className={"selected "+(props.groupType ? props.groupType :"")}>
                    <span className="placeholder-text">
                        {placeholder?.length > maxLength && props.chartGroups === undefined ? placeholder?.substr(0,maxLength)+"...":placeholder} 
                    </span>
                    {
                        props.icon
                        &&
                        <ArrowDropDown_Icon className={isOpen ? 'open':'close'}/>
                    }
                </p>
                {
                    isOpen
                    &&
                    <div ref={ref_inner}>
                        {
                            props.chartGroups
                            ?
                            props.children
                            :
                            <div className="list-content" onClick={selectValue}>
                                {props.children}
                            </div>
                        }
                    </div>
                }
            </div>
        </React.Fragment>
    )
}